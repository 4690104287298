// assets/react/controllers/MyComponent.jsx
import React from 'react';
import styles from "./comparatorresultitem.module.scss";
import pictoRate from "../../images/picto-exchange-rate.svg";
import pictoDelais from "../../images/picto-delais.svg";
import pictoReceiveMethod from "../../images/picto-receive-method.svg";
import pictoSendMethod from "../../images/picto-send-method.svg";
import pictoConvertedAmount from "../../images/picto-received-amount.svg";


export default function ({partner, logo, detailUrl, rate, convertedAmount, speed}) {

    let sendMethods = Object.keys(partner.sendMethods);
    let receiveMethods = Object.keys(partner.receiveMethods);

    return (
        <div className={styles.comparatorResultItem}>

            <div className={styles.logo}>
                <a href={detailUrl}>
                    <img src={logo} alt={partner.name}/>
                </a>
            </div>

            <div className={styles.rate}>
                <div className="d-flex d-md-none align-items-center g-15">
                    <img src={pictoRate} className={"d-inline-flex d-md-none"}/>
                    <span className={"fw-bold"}>Taux de change</span>
                </div>
                <span>{rate}</span>
            </div>

            <div className={styles.infos}>
                <div className="d-flex align-items-center g-5 justify-content-between">
                    <div className={"d-flex align-items-center g-15"}>
                        <img src={pictoDelais}/>
                        <span className={"fw-bold"}>Délais<span
                            className={"d-none d-md-inline-block"}>&nbsp;:</span></span>
                    </div>
                    <span className={"text-end"}>{speed}</span>
                </div>

                <div className="d-flex align-items-center g-5 justify-content-between">
                    <div className={"d-flex align-items-center g-15"}>
                        <img src={pictoSendMethod}/>
                        <span className={"fw-bold"}>Envoi  <span
                            className={"d-none d-md-inline-block"}> des fonds&nbsp;:</span></span>
                    </div>
                    <div className={styles.sendMethodList}>
                        {
                            sendMethods.map((key, index) => {
                                return (
                                    <span className={"send-method " + key}>
                                        {partner.sendMethods[key]} {
                                        index < sendMethods.length - 1 && " | "
                                    }
                                    </span>
                                )
                            })
                        }
                        {
                            /*
                            {% for option in partner.currencySendMethods %}
                            <div className="d-flex align-items-center {% if loop.index > 1 %}mt-2{% endif %}>
                                {% if option == constant("SEND_CURRENCY_BANK", partner) %}
                                <img src={ asset(" build/images/picto-virement-bancaire.svg") }
                                     alt="Envoi des fonds a changer par virement bancaire" width="30"
                                     className="mr-2">
                                    {% elseif  option == constant("SEND_CURRENCY_CASH", partner) %}
                                    <img src={ asset(" build/images/picto-espece.svg") }
                                         alt="Envoi des fonds a changer en espèces" width="30" className="mr-2">
                                        {% elseif  option == constant("SEND_CURRENCY_DEBIT_CARD", partner) %}
                                        <img src={ asset(" build/images/picto-credit-card.svg") }
                                             alt="Envoi des fonds a changer par carte de débit" width="30"
                                             className="mr-2">
                                            {% elseif  option == constant("SEND_CURRENCY_CREDIT_CARD", partner) %}
                                            <img src={ asset(" build/images/picto-credit-card.svg") }
                                                 alt="Envoi des fonds a changer par carte de crédit" width="30"
                                                 className="mr-2">
                                                {% endif %}
                                                <span>
                                        {partner.getCurrencySendMethodText(option)}
                                    </span>
                            </div>
                            {% endfor %}

                            */
                        }
                    </div>
                </div>

                <div className="d-flex align-items-center g-5 justify-content-between">
                    <div className={"d-flex align-items-center g-15"}>
                        <img src={pictoReceiveMethod}/>
                        <span className={"fw-bold"}>Réception <span
                            className={"d-none d-md-inline-block"}> des fonds&nbsp;:</span></span>
                    </div>

                    <div className={styles.receiveMethodList}>
                        {
                            receiveMethods.map((key, index) => {
                                return (
                                    <span className={"receive-method " + key}>
                                        {partner.receiveMethods[key]} {
                                        index < receiveMethods.length - 1 && " | "
                                    }
                                    </span>
                                )
                            })
                        }
                        {
                            /*

                            {% for option in partner.currencyReceiveMethods %}
                        <div className="d-flex align-items-center {% if loop.index > 1 %}mt-2{% endif %}>
                            {% if option == constant("RECEIVE_CURRENCY_BANK", partner) %}
                            <img src={ asset(" build/images/picto-virement-bancaire.svg") }
                                 alt="Réception des fonds par virement bancaire" width="30"
                                 className="mr-2">
                                {% elseif  option == constant("RECEIVE_CURRENCY_CASH", partner) %}
                                <img src={ asset(" build/images/picto-espece.svg") }
                                     alt="Réception des fonds en espèces" width="30" className="mr-2">
                                    {% endif %}
                                    <span>
                                        {partner.getCurrencyReceiveMethodText(option)}
                                    </span>
                        </div>
                        {% endfor %}
                             */
                        }
                    </div>
                </div>
            </div>


            <div className={styles.amountCta}>
                <div className={styles.amount}>
                    <div className={"d-flex align-items-center g-15"}>
                        <img src={pictoConvertedAmount} className={"d-inline-flex d-md-none"}/>
                        <span className={styles.label}>Montant reçu</span>
                    </div>
                    <span className={styles.value}>{convertedAmount}</span>
                </div>

                <div className={styles.cta}>
                    <a href={partner.url} className="btn btn-yellow" target="_blank">
                        Visiter {partner.name}
                    </a>
                    <a href={detailUrl} className={"btn " + styles.btnYellowReversed}>Plus d'infos</a>
                </div>
            </div>
        </div>
    )
}