// assets/react/controllers/MyComponent.jsx
import React, {useState} from 'react';
import styles from "./profilblock.module.scss";
import parse from "html-react-parser";

export default function ({icon, title, desc, link}) {

    const initHeight = 225;
    let [maxHeight, setMaxHeight] = useState(initHeight);

    return (
        <div className={styles.profilBlock}>
            <div className={styles.header}>
                <img src={icon} alt={title} width={32} height={32}/>
                <span>{title}</span>
            </div>

            <div>
                <div className={styles.content} style={{maxHeight: maxHeight}}>
                    {parse(desc)}
                </div>

                <div className="d-flex justify-content-between">
                    <a className={styles.cta} href={link}>
                        <i className="fa fa-angle-right"></i>
                        En savoir plus
                    </a>

                    {
                        maxHeight === initHeight ? (
                            <i className="fa fa-angle-down" onClick={() => setMaxHeight("unset")}></i>
                        ) : (<i className="fa fa-angle-up" onClick={() => setMaxHeight(initHeight)}></i>)
                    }
                </div>
            </div>
        </div>
    )
}