/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you import will output into a single css file (core.css in this case)
import "../css/core-all-style.min.css";
import '../css/core.scss';

// start the Stimulus application
import './bootstrap';

// assets/angularApp.js
import {registerReactControllerComponents} from '@symfony/ux-react';

import 'bootstrap/dist/js/bootstrap.min';

import "./core-all-js.min";
import "./coinmarketcap-currency.min";
import "./lazysizes.min";

// Registers React controller components to allow loading them from Twig
//
// React controller components are components that are meant to be rendered
// from Twig. These component then rely on other components that won't be called
// directly from Twig.
//
// By putting only controller components in `react/controllers`, you ensure that
// internal components won't be automatically included in your JS built file if
// they are not necessary.
registerReactControllerComponents(require.context('../react/controllers', true, /\.(j|t)sx?$/));

let angularApp = angular.module("app", []);
angularApp.run(["$rootScope", function ($rootScope) {
}]);
angularApp.factory("baseUrl", ["$window", function ($window) {
    return $window["baseUrl"]
}]);
angularApp.controller("CoreController", ["$scope", function ($scope) {
}]);

export default angularApp;