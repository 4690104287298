// assets/react/controllers/MyComponent.jsx
import React from 'react';
import parse from "html-react-parser";
import styles from "./accordionblock.module.scss";

export default function ({id, title, content, open = false}) {

    return (
        <div id={"accordion" + id}>
            <div className={"accordion-item " + styles.accordionItem}>
                <h2 className="accordion-header" id={"heading" + id}>
                    <button className={"accordion-button " + (!open ? "collapsed " : "") + styles.accordionButton}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + id} aria-expanded={open} aria-controls={"collapse" + id}>
                        {
                            parse(title)
                        }
                    </button>
                </h2>
                <div id={"collapse" + id} className={"accordion-collapse collapse " + (open ? "show" : "")}
                     aria-labelledby={"heading" + id}
                     data-bs-parent={"#accordion" + id}>
                    <div className={"accordion-body " + styles.accordionBody}>
                        {
                            parse(content)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}