// assets/react/controllers/MyComponent.jsx
import React, {useEffect, useState} from 'react';
import styles from "./toppartnerblock.module.scss"

export default function ({comparatorLink, from = "EUR", to = "CHF", partnerList = [], target = "_self"}) {

    const [partners, setPartners] = useState(partnerList);
    const [loading, setLoading] = useState(true);

    const getRates = (retry = 0) => {
        if (partners.length == 0) {
            $.ajax({
                url: baseUrl + "/today-partner-exchange-rate"
            }).done(function (data) {
                if (data.partnerResults[0].convertedAmount == 0) {
                    if (retry < 3)
                        setTimeout(() => {
                            getRates(++retry)
                        }, retry * 1000);
                    return;
                }
                setPartners(data.partnerResults);
                setLoading(false);
            }).fail(function (jqXhr) {
                if (retry < 3)
                    setTimeout(() => {
                        getRates(++retry)
                    }, retry * 1000);
            });
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRates();
    }, []);

    let today = new Date();
    let date = today.toLocaleDateString() + " " + today.getHours() + "h" + (today.getMinutes() < 10 ? "0" : "") + today.getMinutes();

    if (loading) {
        return (
            <div className={styles.topPartnerBlock}>
                <span className={styles.title}>
                    Taux {from}/{to} du jour
                </span>
                <span className={styles.date + " skeleton skeleton-text"}></span>

                <div className={styles.partners}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
                <div className={styles.partners}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
                <div className={styles.partners}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
                <div className={styles.partners}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
                <div className={styles.partners}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
            </div>
        )
    }

    return (


        <div className={styles.topPartnerBlock}>
            <span className={styles.title}>
                Taux {from}/{to} du jour
            </span>
            <span className={styles.date}>
                {date}
            </span>

            {
                partners.map((partner) => (
                    <a className={styles.partners} href={partner.url} target={target}>
                        <span className={styles.partnerName}>{partner.partner.name}</span>
                        <span className={styles.partnerRate}>{parseFloat(partner.rate).toFixed(4)}</span>
                    </a>
                ))
            }

            <a href={comparatorLink} className={styles.cta} target={target}>
                Je compare les taux
            </a>
        </div>
    )
}
