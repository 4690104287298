// assets/react/controllers/MyComponent.jsx
import React from 'react';
import styles from "./testimonialblock.module.scss"

export default function ({note, comment, pseudo, img}) {

    const _renderStars = () => {
        let notes = [...Array(5)];

        return (
            <div className="stars">
                {
                    notes.map((e, i) => (
                        <i className={"fa fa-star " + (i + 1 > note && "disabled")}></i>
                    ))
                }
            </div>
        )
    }

    return (
        <div className={styles.testimonialBlock}>
            <div className={styles.testimonialContent}>
                {_renderStars()}
                <span>{comment}</span>
            </div>

            <div className={styles.pseudoBlock}>
                <img src={img} alt={pseudo} width={60} height={60}/>
                <div>
                    <span className={styles.pseudo}>
                        {pseudo}
                    </span>
                </div>
            </div>
        </div>
    )
}