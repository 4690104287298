// assets/react/controllers/MyComponent.jsx
import React, {useEffect, useState} from 'react';
import styles from "./pairchangeblock.module.scss"
import arrowUp from "../../images/green-arrow-up.svg";
import arrowDown from "../../images/red-arrow-down.svg";

export default function (props) {

    const [currencies, setCurrencies] = useState([]);
    const [cryptos, setCryptos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        $.ajax({
            url: baseUrl + "/crypto-and-currency-rate"
        }).done(function (data) {
            setCryptos(data.cryptos);
            setCurrencies(data.currencies);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!loading) {
            currencies.map((currency) => {
                let {currencyFrom, currencyTo, rates, pipeDiff} = currency;
                let isUp = pipeDiff >= 0;
                _setChart(currencyFrom, currencyTo, rates, isUp);
            })
            cryptos.map((crypto) => {
                let {rates} = crypto;
                let lastRate = rates[0];
                let {cryptoFrom, cryptoTo, priceChangePercent} = lastRate;
                let isUp = priceChangePercent >= 0;
                _setCryptoChart(cryptoFrom, cryptoTo, rates, isUp);
            })
        }
    }, [loading])

    const _setChart = (currencyFrom, currencyTo, rates, isUp) => {
        var data = rates;

        var i = 0;
        var dataLength = data.length;
        var lastCHFEURRate = data[dataLength - 1][1];
        var ohlc = [];
        var d = new Date();

        for (i; i < dataLength; i += 1) {
            ohlc.push([
                (data[i][0]), // the date
                data[i][1] // open
            ]);
        }

        Highcharts.stockChart('chart' + currencyFrom + currencyTo, {
            chart: {
                height: 88,
                marginLeft: 0,
                marginRight: 0
            },
            xAxis: {
                visible: false
            },
            yAxis: {
                visible: false,
                gridLineWidth: 0
            },
            credits: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            series: [{
                type: 'areaspline',
                threshold: null,
                color: isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)",
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)"],
                        [1, isUp ? "rgba(224, 255, 158, 0)" : "rgba(253, 191, 198, 0)"]
                    ]
                },
                name: currencyFrom + '/' + currencyTo,
                data: ohlc,
            }]
        });
    }

    const _setCryptoChart = (cryptoFrom, cryptoTo, rates, isUp) => {
        var data = rates;

        var i = 0;
        var dataLength = data.length;
        var lastCHFEURRate = data[dataLength - 1][1];
        var ohlc = [];
        var d = new Date();

        for (i; i < dataLength; i += 1) {
            let curr = data[i];
            ohlc.push([
                curr.timestamp, // the date
                parseFloat(curr.lastPrice) // open
            ]);
        }

        Highcharts.stockChart('cryptochart' + cryptoFrom + cryptoTo, {
            chart: {
                height: 88,
                marginLeft: 0,
                marginRight: 0
            },
            xAxis: {
                visible: false
            },
            yAxis: {
                visible: false,
                gridLineWidth: 0
            },
            credits: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            series: [{
                type: 'areaspline',
                threshold: null,
                color: isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)",
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, isUp ? "rgba(177, 239, 46, 0.5)" : "rgba(247, 150, 162, 0.5)"],
                        [1, isUp ? "rgba(224, 255, 158, 0)" : "rgba(253, 191, 198, 0)"]
                    ]
                },
                name: cryptoFrom + '/' + cryptoTo,
                data: ohlc,
            }]
        });
    }

    if (loading) {
        let arr = [1, 2, 3, 4, 5, 6];
        return (<div className={styles.pairRateList}>
            {arr.map(() => ((<div className={styles.pairRateCard}>
                <div className={styles.pairRate}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
                <div className={styles.pairRateDiff}>
                    <span className={"skeleton skeleton-text"}></span>
                    <span className={"skeleton skeleton-text"}></span>
                </div>
            </div>)))}
        </div>)
    }

    return (
        <div className={styles.pairRateList}>
            {
                currencies.map((currency, index) => {
                    let {currencyFrom, currencyTo, currentRate, pipeDiff, pourcentDiff, url} = currency;
                    let isUp = pipeDiff >= 0;

                    return (
                        <a className={styles.pairRateCard} href={url}>
                            <div className={styles.pairRate}>
                                <div>
                                    <img src={isUp ? arrowUp : arrowDown} alt="" width={32} height={32}/>
                                    <span className={styles.pair}>{currencyFrom} / {currencyTo}</span>
                                </div>
                                <span>{currentRate}</span>
                            </div>

                            <div id={"chart" + currencyFrom + currencyTo}></div>

                            <div className={styles.pairRateDiff}>
                            <span>
                                <i className={"fa " + (isUp ? 'fa-caret-up' : 'fa-caret-down')}></i>
                                {pipeDiff}
                            </span>
                                <span className={isUp ? 'green' : 'red'}>
                                {isUp && ("+")}
                                    {pourcentDiff}%
                        </span>
                            </div>
                        </a>

                    )
                })}
            {
                cryptos.map((crypto, index) => {
                    let {rates, url} = crypto;
                    let lastRate = rates[0];
                    let {cryptoFrom, cryptoTo, lastPrice, priceChange, priceChangePercent} = lastRate;
                    let isUp = priceChangePercent >= 0;
                    return (

                        <a href={url} className={styles.pairRateCard}>
                            <div className={styles.pairRate}>
                                <div>
                                    <img src={isUp ? arrowUp : arrowDown} alt="" width={32}
                                         height={32}/>
                                    <span className={styles.pair}>{cryptoFrom} / {cryptoTo}</span>
                                </div>
                                <span>{lastPrice}</span>
                            </div>
                            <div id={"cryptochart" + cryptoFrom + cryptoTo}></div>
                            <div className={styles.pairRateDiff}>
                                <span>
                                    <i className={"fa " + (isUp ? 'fa-caret-up' : 'fa-caret-down')}></i>
                                    {priceChange}
                                </span>
                                <span className={isUp ? 'green' : 'red'}>
                                    {isUp && ("+")}
                                    {priceChangePercent}%
                                </span>
                            </div>
                        </a>
                    )
                })
            }
        </div>
    )
}